import { classNames } from '@frontend/string';
import { useMultiStepContext } from '../../multi-step.context';
import { multiStepStyles, StepperColorProps } from '../../multi-step.styles';
import { MultiStepDisplay, StepData } from '../../multi-step.types';

export type MultiStepStepperItemProps = {
  className?: string;
  step: StepData;
  index: number;
  display?: MultiStepDisplay;
  onClick?: () => void;
  clickDisabled?: boolean;
};
export function MultiStepStepperItem({
  className,
  onClick,
  clickDisabled,
  step,
  index, // parent index
  display,
}: MultiStepStepperItemProps) {
  const {
    activeParentStepIndex,
    parentSteps,
    setActiveStep,
    isActiveParentStep,
    isValidParentStep,
    isCompleteParentStep,
    getParentStepPercentProgress,
  } = useMultiStepContext();

  const prevStep = parentSteps[index - 1] || null;
  const isClickable = !clickDisabled && (step.isComplete || !!prevStep?.isComplete);

  const styleProps: StepperColorProps = {
    hasPrev: index < activeParentStepIndex,
    isNext: index === activeParentStepIndex + 1,
    active: isActiveParentStep(step.id),
    complete: isCompleteParentStep(step.id),
    valid: isValidParentStep(step.id),
    percentComplete: getParentStepPercentProgress(step.id),
    index,
    maxIndex: parentSteps.length - 1,
    isClickable,
    clickDisabled,
  };

  const handleStepClick = () => {
    if (!isClickable) {
      return;
    }
    if (typeof onClick === 'function') {
      onClick();
    }

    /**
     * Default functionality
     */

    setActiveStep(step.id);
  };

  return (
    <div
      className={classNames('stepper__item', className)}
      onClick={handleStepClick}
      css={multiStepStyles.stepperItem(styleProps)}
    >
      <div className='stepper__icon-wrapper' css={multiStepStyles.stepperIconWrapper}>
        <div
          className='stepper__progress stepper__progress--bg'
          css={multiStepStyles.stepProgress(styleProps, false)}
        ></div>
        <div
          className='stepper__progress stepper__progress--primary'
          css={multiStepStyles.stepProgress(styleProps, true)}
        ></div>
        <div className='stepper__icon' css={multiStepStyles.stepperIcon(styleProps)}>
          <div className='stepper__icon-text'>{index + 1}</div>
        </div>
      </div>
      {display === 'full' && (
        <div className='stepper__label' css={multiStepStyles.stepperLabel}>
          <div className='stepper__label-text' css={multiStepStyles.stepperLabelText}>
            {step.label}
          </div>
        </div>
      )}
    </div>
  );
}
