import type { FocusEvent, KeyboardEvent } from 'react';
import { addMinutes, differenceInDays, format, startOfDay, subMinutes } from 'date-fns';
import { isFunction } from 'lodash-es';
import { digitsOnly } from '../../../../helpers';
import type { FieldChangeEvent } from '../../hooks//types';
import type { IntervalProps, TimeInterval, TimeMeridiem, TimePickerHours, TimeUnit } from './types';

export enum MathMethods {
  Add = 'add',
  Subtract = 'subtract',
}

export type AddOrSubtract = (typeof MathMethods)['Add'] | (typeof MathMethods)['Subtract'];

export const isValidTimeFormat = (value: string) => /^(1[0-2]|0?[1-9]):[0-5]\d[ap]m$/i.test(value);

export const isTimeStringFormat = (value: string) => /^([0-1]?\d|2[0-3]):[0-5]\d:00$/.test(value);

export const is24HrFormat = (value: string) => /^([0-1]?\d|2[0-3]):[0-5]\d(:00)?$/.test(value);

const enforce24HrHours = (value: number | string, meridiem?: TimeMeridiem) => {
  let numericValue = +value;
  if (meridiem) {
    if (meridiem === 'am') {
      if (numericValue === 12) numericValue = 0;
    } else if (numericValue < 12) {
      numericValue += 12;
    }
  }
  return numericValue >= 24 ? 0 : (numericValue as TimePickerHours);
};

const enforce12HrHours = (value: string | number) => {
  const num = +value;
  if (num === 0) return 12;
  return num < 13 ? num : num - 12;
};

export const stringToTimeUnit = (time = ''): TimeUnit | undefined => {
  const is12HrFormat = isValidTimeFormat(time);
  if (!time || (!is12HrFormat && !is24HrFormat(time))) return;
  let meridiem;
  if (is12HrFormat) {
    meridiem = time
      .toLowerCase()
      .match(/([ap]m)/)!
      .shift();
    time = time.replace(/[a-z]/gi, '');
  }
  const [hours, minutes] = time.split(':').map((v) => +v);
  return {
    hours: enforce24HrHours(hours ?? 0, meridiem),
    minutes: minutes && minutes < 60 ? minutes : 0,
  };
};

const addLeadingZero = (num: number) => `${num < 10 ? '0' : ''}${num}`;

export const toTimeString = (interval?: TimeUnit) => {
  if (!interval) return '';
  const { hours, minutes } = interval;
  return `${addLeadingZero(hours)}:${addLeadingZero(minutes)}:00`;
};

export const to12HrString = (interval?: TimeUnit, meridiem?: TimeMeridiem) => {
  if (!interval) return '';
  const { hours, minutes } = interval;
  return `${enforce12HrHours(hours)}:${addLeadingZero(minutes)}${meridiem ? meridiem : hours > 11 ? 'pm' : 'am'}`;
};

/**
 * @param time hh:mm:ss
 * @returns date string
 */
const getDateStringFrom24HrFormat = (time: string | undefined) => {
  if (time) {
    const [hh, mm, ss] = time.split(':').map((num) => parseInt(num));
    return hh ? new Date(new Date().setHours(hh, mm, ss)).getTime() : 0;
  }
  return 0;
};

type GetAdjustedTimeArgs = {
  timeValue: string;
  method: AddOrSubtract;
  timeInterval: number;
};

/**
 * @param time hh:mm:ss
 * @param method
 * @param timeInterval number
 * @returns an object that mimics what the TimeField change handlers
 * expect with minutes added or subtracted
 * in two formats: `displayValue` (e.g. 1:30pm) and `value` (e.g. 13:30:00)
 * (forces the user into a valid time range entry)
 */
export const getAdjustedTime = ({ timeValue, method, timeInterval }: GetAdjustedTimeArgs) => {
  if (timeValue === '') {
    return { displayValue: '', value: '00:00:00' };
  }
  const adjustByMethod = method === MathMethods.Add ? addMinutes : subMinutes;
  const [hh, mm, ss] = timeValue.split(':').map((number) => parseInt(number));
  const date = new Date().setHours(hh ?? 0, mm, ss);
  const adjustedTime = adjustByMethod(date, timeInterval);
  let twentyFourHourTime = '';
  try {
    twentyFourHourTime = format(adjustedTime, 'HH:mm:ss') || '';
  } catch (error: unknown) {
    throw new RangeError('Invalid time value');
  }
  let displayValue = '';
  try {
    displayValue = format(adjustedTime, 'h:mmaaa');
  } catch (e) {
    displayValue = 'Invalid';
    throw new RangeError('Invalid time value');
  }
  return { displayValue, value: twentyFourHourTime };
};

interface BoundaryDayArgs {
  // timeValue will be 'hh:mm:ss'
  timeValue: string;
  method: AddOrSubtract;
  timeInterval: number;
}

// negative in past, zero is today, positive in future
type YesterdayTodayTomorrow = -1 | 0 | 1 | string;

export const daysDifference = ({ timeValue, method, timeInterval }: BoundaryDayArgs): YesterdayTodayTomorrow => {
  const isValidTimeFormat = /^(?:(?:([00]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/;
  if (!isValidTimeFormat.test(timeValue)) return 'timeValue must be hh:mm:ss';
  const [hh, mm, ss] = timeValue.split(':').map((number: string) => parseInt(number));
  if (hh === undefined || mm === undefined || ss === undefined) {
    throw new RangeError('Invalid time value');
  }
  const date1 = new Date(new Date().setHours(hh, mm, ss));
  let date2 = new Date(new Date().setHours(hh, mm, ss));

  if (method === MathMethods.Add) {
    date2 = addMinutes(date2, timeInterval);
  }
  if (method === MathMethods.Subtract) {
    date2 = subMinutes(date2, timeInterval);
  }

  return differenceInDays(startOfDay(date2), startOfDay(date1)) as YesterdayTodayTomorrow;
};

/**
 * @param startTime value in Start time field in hh:mm:ss
 * @param endTime value in End time field in hh:mm:ss
 * @returns `true` if the startTime is before the endTime,
 * `false` if endTime is before startTime or if startTime === endTime
 */
export const isValidTimeRange = (startTime: string | undefined, endTime: string | undefined) =>
  startTime && endTime && getDateStringFrom24HrFormat(startTime) - getDateStringFrom24HrFormat(endTime) < 0;

export const getTimeIntervals = ({ maxTime, minTime, interval = 15 }: IntervalProps): TimeInterval[] => {
  const startTime = stringToTimeUnit(minTime) ?? { hours: 0, minutes: 0 };
  const endTime = stringToTimeUnit(maxTime) ?? { hours: 23, minutes: 59 };
  const end = endTime.hours + endTime.minutes / 60;

  const intervals: TimeInterval[] = [];
  let nextHour = startTime.hours;
  let nextMinutes = startTime.minutes;

  while (nextHour + nextMinutes / 60 <= end) {
    intervals.push({
      displayValue: to12HrString({ hours: nextHour, minutes: nextMinutes }),
      value: toTimeString({ hours: nextHour, minutes: nextMinutes }),
    });

    nextMinutes += interval;
    if (nextMinutes > 59) {
      nextHour += 1;
      if (nextMinutes === 60) {
        nextMinutes = 0;
      } else {
        nextMinutes = nextMinutes - 60;
      }
    }
  }
  return intervals;
};

// time input helpers
const allowedChars = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  ':',
  'a',
  'A',
  'p',
  'P',
  'm',
  'M',
  'ArrowLeft',
  'ArrowRight',
  'Backspace',
  'Delete',
  'Tab',
];

export const isAllowedTimeChar = (event: KeyboardEvent) => allowedChars.includes(event.key);

const enforceMinutes = (value: string | number) => (+value > 59 ? 59 : +value);

const extractMeridiem = (value: string): TimeMeridiem | undefined => {
  const matches = value.toLowerCase().match(/(am|pm|a|p)/gi);
  if (!matches) return;
  const legitMatch: unknown = matches.filter((m) => m.length > 1).pop();
  return legitMatch ? (legitMatch as TimeMeridiem) : matches.pop() === 'a' ? 'am' : 'pm';
};

const modifyForMeridiem = (meridiem: TimeMeridiem) => (hours: number) => {
  if (meridiem === 'pm' && hours < 12) return hours + 12;
  if (meridiem === 'am' && hours > 11) return hours - 12;
  return hours;
};

export const freeformToTimeUnit = (value: string): TimeUnit | undefined => {
  const meridiem = extractMeridiem(value);
  const hoursModifier = meridiem ? modifyForMeridiem(meridiem) : (hours: number) => hours;
  // see if we have time units to work with
  const units = value
    .replace(/[^0-9:]/g, '') // reduce to digits + colons
    .split(':')
    .filter(Boolean);

  let time = { hours: 0, minutes: 0 };

  // if we have colon-divided units, use them
  if (units.length > 1) {
    time = {
      hours: hoursModifier(+digitsOnly(units[0])),
      minutes: enforceMinutes(digitsOnly(units[1])),
    };
  } else {
    // otherwise we're dealing with total freeform
    const usableDigits = digitsOnly(value);
    const chars = usableDigits.length;

    if (!chars) return;

    if (chars >= 4) {
      const hours = +usableDigits.substr(0, 2);
      if (hours > 24 && usableDigits[0] !== undefined) {
        time = {
          hours: hoursModifier(+usableDigits[0]),
          minutes: enforceMinutes(usableDigits.substr(1, 2)),
        };
      } else {
        time = {
          hours: enforce24HrHours(hoursModifier(hours)),
          minutes: enforceMinutes(usableDigits.substr(2, 2)),
        };
      }
    } else if (chars === 3 && usableDigits[1] !== undefined && usableDigits[0] !== undefined) {
      const is24Hr = usableDigits[0] === '1' && +usableDigits[1] > 5;
      time = {
        hours: hoursModifier(is24Hr ? +usableDigits.substr(0, 2) : +usableDigits[0]),
        minutes: is24Hr ? enforceMinutes(`${usableDigits[2]}0`) : enforceMinutes(usableDigits.substr(1)),
      };
    } else if (chars === 2) {
      if (+usableDigits > 12 && usableDigits[0] !== undefined) {
        time = {
          hours: hoursModifier(+usableDigits[0]),
          minutes: enforceMinutes(`${usableDigits[1]}0`),
        };
      } else {
        time.hours = hoursModifier(+usableDigits);
      }
    } else if (chars === 1) {
      time.hours = hoursModifier(+usableDigits);
    }
  }

  return time as TimeUnit;
};

// formatters for conforming freeform typing to display and time string values
export const conformTo12HrFormat = (value: string): string => to12HrString(freeformToTimeUnit(value));

export const conformToTimeString = (value: string): string => toTimeString(freeformToTimeUnit(value));

// input handlers for custom behavior
type KeydownEvent = KeyboardEvent<HTMLInputElement>;

export const inputKeyDownHandler = (handler?: (event: KeydownEvent) => void) => (event: KeydownEvent) => {
  // pass through an incoming keydown handler first
  // eg arrow keys for moving through time interval list
  if (isFunction(handler)) {
    event.persist();
    handler?.(event);
  }

  if (!allowedChars.includes(event.key)) {
    event.preventDefault();
    event.stopPropagation();
  }
};

export const inputBlurHandler =
  (onBlur: (event?: FocusEvent<HTMLInputElement>) => void, onChange: (event: FieldChangeEvent) => void) =>
  (event?: FocusEvent<HTMLInputElement>) => {
    onBlur(event);
    const trimmed = event?.target.value.trim();

    if (event?.target.name && trimmed && !isValidTimeFormat(trimmed)) {
      onChange({
        name: event?.target.name,
        value: conformTo12HrFormat(trimmed),
      });
    }
  };
