import React from 'react';
import { useModalControl, ModalControlProps } from '../modal';
import { MultiStepDisplay, PrevNextStepProps, StepData } from './multi-step.types';
import { useCustomContainerBreakpoints } from './utils/use-custom-breakpoints';

export type MultiStepControlProps = {
  trackingId?: string;

  /**
   * Manually set the initial active step.
   * If not set, default step will be the first step.
   */
  initialActiveStep?: string;

  /**
   * Called when a new step will be set BEFORE the internal state
   * changes.
   *
   * Move Info:
   * - activeStepIndex: The index of the current active step.
   * - targetIndex: The index of the step being set.
   * - movement: The difference between the target and active step indexes. Use to see direction. Positive = forward, negative = backward..
   *
   * Return false to prevent the step change.
   */
  onChangeStep?: (
    step: string,
    moveInfo: { activeStepIndex: number; targetIndex: number; movement: number }
  ) => false | void;

  /**
   * If true, reduces main component's padding and removes border radius and shadow.
   *
   * Default: false.
   */
  isInModal?: boolean;

  /**
   * Global "cancel" action visiblity setting. Will hide/show "cancel" button
   * for all steps.
   *
   * Individual steps with the `showCancelAction` prop will override this.
   *
   * Default: When onCancel is set, true. Otherwise, false.
   */
  showCancelAction?: boolean;

  /**
   * Callback when all steps are completed, and the final
   * step's primary/advance button is clicked.
   */
  onComplete: () => void;

  /**
   * Callback when any step's > header > "cancel" button is clicked.
   */
  onCancel?: () => void;

  /**
   * Any/all stepper props, passed to stepper component directly.
   */
  stepper?: {
    show?: boolean;
    clickDisabled?: boolean;
    scrollDisabled?: boolean;
    display?: MultiStepDisplay;
  };

  onChangeBreakpoint?: (breakpoint: string) => false | void;
};

export function useMultiStepControl(
  {
    onComplete,
    onCancel,
    showCancelAction: showCancelActionInitial,
    initialActiveStep,
    onChangeStep,
    onChangeBreakpoint,
    isInModal: isInModalInitial = false,
    stepper = {},
    trackingId,
  }: MultiStepControlProps,
  isModalOpen = false
) {
  const {
    show: showInitial = true,
    display: displayInitial = 'full',
    clickDisabled: clickDisabledInitial,
    scrollDisabled: scrollDisabledInitial,
  } = stepper;

  /**
   * Global state
   */
  const [isInModal, setIsInModal] = React.useState(isInModalInitial);

  /**
   * Global step state management
   */
  const [showCancelAction, setShowCancelAction] = React.useState(
    typeof showCancelActionInitial === 'boolean' ? showCancelActionInitial : typeof onCancel === 'function'
  );
  const [activeStep, setActiveStepState] = React.useState(initialActiveStep || '');

  /**
   * Stepper state management
   */
  const [showInternal, setShow] = React.useState(showInitial);
  const [display, setDisplay] = React.useState(displayInitial);
  const [clickDisabled, setClickDisabled] = React.useState(clickDisabledInitial);
  const [scrollDisabled, setScrollDisabled] = React.useState(scrollDisabledInitial);

  const showStepper = React.useMemo(() => {
    if (display === 'xs') {
      return false;
    }

    return showInternal;
  }, [showInternal, display]);

  const [breakpoint, containerRef] = useCustomContainerBreakpoints({ sm: 530, xs: 320 }, isModalOpen || !isInModal);

  const [steps, setSteps] = React.useState<StepData[]>([]);

  // console.log('steps was', steps);

  const parentSteps = React.useMemo(() => {
    return steps.filter((s) => !s.parentId);
  }, [steps]);

  const activeStepIndex = React.useMemo(() => {
    return steps.findIndex((s) => s.id === activeStep);
  }, [activeStep, steps]);

  const activeParentStepIndex = React.useMemo(() => {
    const found = steps[activeStepIndex];

    if (!found) {
      return -1;
    }

    /**
     * No parent ID. Get parent index.
     */
    if (!found.parentId) {
      return parentSteps.findIndex((s) => s.id === found.id);
    }

    return parentSteps.findIndex((s) => s.id === found.parentId);
  }, [activeStepIndex, parentSteps, steps]);

  const hasMoreSteps = React.useMemo(() => {
    return steps.length - 1 > activeStepIndex;
  }, [activeStepIndex, steps]);

  const setStepData = React.useCallback((data: StepData) => {
    setSteps((prev) => {
      const found = prev.find((s) => s.id === data.id);

      let toSet: StepData[] = [];
      if (found) {
        toSet = prev.map((s) => (s.id === data.id ? data : s));
      } else {
        toSet = [...prev, data];
      }

      return toSet;
    });
  }, []);

  const getStepData = React.useCallback(
    (id: string) => {
      return steps.find((s) => s.id === id);
    },
    [steps]
  );

  const setActiveStep = React.useCallback(
    (step: string) => {
      const targetIndex = steps.findIndex((s) => s.id === step);

      if (
        typeof onChangeStep === 'function' &&
        onChangeStep(step, { activeStepIndex, targetIndex, movement: targetIndex - activeStepIndex }) === false
      ) {
        // step change prevented via callback
        return;
      }

      setActiveStepState(step);
    },
    [activeStep, onChangeStep, activeStepIndex]
  );

  const goNextStep = React.useCallback(
    ({ orIndex, orStep }: PrevNextStepProps = {}) => {
      let nextIndex = activeStepIndex + 1;
      if (typeof orStep === 'string') {
        nextIndex = steps.findIndex((s) => s.id === orStep);
      } else if (typeof orIndex === 'number') {
        nextIndex = orIndex;
      }

      if (nextIndex >= steps.length) {
        return onComplete();
      }

      const nextStep = steps[nextIndex];
      if (nextStep) {
        setActiveStep(nextStep.id);
      }
    },
    [steps, activeStepIndex, setActiveStep, onComplete]
  );

  const goToStep = React.useCallback(
    ({ orIndex, orStep }: PrevNextStepProps = {}) => {
      let foundIndex: number | null = null;

      if (typeof orStep === 'string') {
        foundIndex = steps.findIndex((s) => s.id === orStep);
      } else if (typeof orIndex === 'number') {
        foundIndex = orIndex;
      }

      if (foundIndex === null || !steps[foundIndex]) {
        console.warn(`Invalid step index or ID: ${orIndex || orStep}`);
        return false;
      }

      const foundStep = steps[foundIndex];
      if (foundStep) {
        setActiveStep(foundStep.id);
      }

      return true;
    },
    [steps, activeStepIndex, setActiveStep, onComplete]
  );

  const goPrevStep = React.useCallback(
    ({ orIndex, orStep }: PrevNextStepProps = {}) => {
      let prevIndex = Math.max(0, activeStepIndex - 1);
      if (typeof orStep === 'string') {
        prevIndex = steps.findIndex((s) => s.id === orStep);
      } else if (typeof orIndex === 'number') {
        prevIndex = orIndex;
      }

      const prevStep = steps[prevIndex];
      if (prevStep) {
        setActiveStep(prevStep.id);
      }
    },
    [steps, activeStepIndex, setActiveStep]
  );

  React.useEffect(() => {
    if (typeof onChangeBreakpoint === 'function' && onChangeBreakpoint(breakpoint.size) === false) {
      // change prevented via callback
      return;
    }
    setDisplay(breakpoint.size);
  }, [breakpoint.size, onChangeBreakpoint]);

  const watchedProps = {
    showCancelActionInitial,
    isInModalInitial,
    showInitial,
    displayInitial,
    clickDisabledInitial,
    scrollDisabledInitial,
  };
  const prevWatchedProps = React.useRef(watchedProps);

  /**
   * Watch for changes to config props and update internal state
   */
  React.useEffect(() => {
    Object.entries(watchedProps).forEach(([key, value]) => {
      if (value !== prevWatchedProps.current[key]) {
        prevWatchedProps.current[key] = value;
        switch (key) {
          case 'showCancelActionInitial':
            setShowCancelAction(value as boolean);
            break;
          case 'isInModalInitial':
            setIsInModal(value as boolean);
            break;
          case 'showInitial':
            setShow(value as boolean);
            break;
          case 'displayInitial':
            setDisplay(value as MultiStepDisplay);
            break;
          case 'clickDisabledInitial':
            setClickDisabled(value as boolean);
            break;
          case 'scrollDisabledInitial':
            setScrollDisabled(value as boolean);
            break;
        }
      }
    });
  }, [JSON.stringify(watchedProps)]);

  /**
   * Returns true if current parent step is active,
   * or if a child step of current parent step is active.
   */
  const isActiveParentStep = React.useCallback(
    (stepId: string) => {
      const foundStep = getStepData(stepId);

      if (!foundStep) {
        return false;
      }

      /**
       * Is directly active.
       */
      if (foundStep.id === activeStep) {
        return true;
      }

      return foundStep.childSteps.includes(activeStep);
    },
    [activeStep, getStepData]
  );

  /**
   * Returns true if current parent step is valid,
   * or if all child steps of current parent step are valid.
   */
  const isValidParentStep = React.useCallback(
    (stepId: string) => {
      const foundStep = getStepData(stepId);

      if (!foundStep) {
        return false;
      }

      const parentId = foundStep.parentId;
      /**
       * Is parent step. Return true if active.
       */
      if (!parentId) {
        return foundStep.isValid;
      }

      const parentStep = getStepData(parentId);

      if (!parentStep) {
        return false;
      }

      /**
       * Not parent step. All child steps must be valid.
       */

      return parentStep.childSteps.every((childId) => getStepData(childId)?.isValid);
    },
    [getStepData]
  );

  /**
   * Returns true if parent step is complete,
   * or if all child steps of parent step are complete.
   */

  const isCompleteParentStep = React.useCallback(
    (stepId: string) => {
      const foundStep = getStepData(stepId);

      if (!foundStep) {
        return false;
      }

      const parentId = foundStep.parentId;
      /**
       * Is parent step. Return true if active.
       */
      if (!parentId) {
        return foundStep.isComplete;
      }

      const parentStep = getStepData(parentId);

      if (!parentStep) {
        return false;
      }

      /**
       * Not parent step. All child steps must be valid.
       */

      return parentStep.childSteps.every((childId) => getStepData(childId)?.isComplete);
    },
    [getStepData]
  );

  /**
   * Return 50% for single parents.
   * Return percent complete for parents with children.
   */
  const getParentStepPercentComplete = React.useCallback(
    (stepId: string) => {
      const parent = getStepData(stepId);

      if (!parent || !parent.childSteps.length) {
        return parent?.isComplete ? 100 : 0;
      }

      const totalSteps = 1 + parent.childSteps.length;
      const totalComplete =
        (parent.isComplete ? 1 : 0) + parent.childSteps.filter((childId) => !!getStepData(childId)?.isComplete).length;

      return (totalComplete / totalSteps) * 100;
    },
    [getStepData]
  );

  const getParentStepPercentProgress = React.useCallback(
    (stepId: string) => {
      const thisParentStepIndex = parentSteps.findIndex((s) => s.id === stepId);
      const thisStepIndex = steps.findIndex((s) => s.id === stepId);
      const thisStepData = getStepData(stepId);

      if (!thisStepData || thisParentStepIndex === -1 || thisStepIndex === -1) {
        return 0;
      }

      if (!thisStepData.childSteps.length) {
        return activeParentStepIndex > thisParentStepIndex ? 100 : 0;
      }

      const finalIndex = thisStepIndex + thisStepData.childSteps.length;
      if (activeStepIndex > finalIndex) {
        return 100;
      }

      const totalSteps = 1 + thisStepData.childSteps.length;
      const stepsPassed = totalSteps - Math.max(0, finalIndex + 1 - activeStepIndex);

      return (stepsPassed / totalSteps) * 100;
    },
    [getStepData, activeParentStepIndex, parentSteps, steps, activeStepIndex]
  );

  return {
    // global values
    isInModal,
    setIsInModal,
    onCancel,
    onComplete,
    showCancelAction,
    setShowCancelAction,
    trackingId,

    // computed step values
    parentSteps,
    activeStepIndex,
    activeParentStepIndex,
    hasMoreSteps,
    isActiveParentStep,
    isValidParentStep,
    isCompleteParentStep,
    getParentStepPercentComplete,
    getParentStepPercentProgress,

    // step values
    steps,
    setSteps,
    activeStep,
    setActiveStep,
    setStepData,
    getStepData,

    goNextStep,
    goPrevStep,
    goToStep,
    stepper: {
      show: showStepper,
      setShow,
      display,
      setDisplay,
      clickDisabled,
      setClickDisabled,
      scrollDisabled,
      setScrollDisabled,
    },

    // breakpoint values
    breakpoint,
    containerRef,
  };
}

export function useMultiStepModalControl(multiStepProps: MultiStepControlProps, modalProps: ModalControlProps) {
  const modal = useModalControl(modalProps);
  const step = useMultiStepControl({ ...multiStepProps, isInModal: true }, modal.modalProps.show);

  return {
    ...modal,
    ...step,
  };
}

export type MultiStepControl = ReturnType<typeof useMultiStepControl>;
export type MultiStepModalControl = ReturnType<typeof useMultiStepModalControl>;
